<template>
  <v-dialog v-model="dialog" persistent max-width="430px">
    <v-card class="change-document-modal">
      <v-card-title>
        <span class="change-document-modal__title">Choisissez la document</span>
        <v-btn fab dark color="info" text @click="$emit('closeModal')">
          <v-icon dark large> mdi-window-close </v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <div class="change-document-modal__body">
          <v-form
            class="form"
            @submit.prevent="submit"
            v-model="valid"
            ref="form"
          >
            <div class="form__body">
              <v-file-input
                v-model="model.file"
                v-if="mode === 'update'"
                label="Entrée de fichier"
                accept="image/png, image/jpeg, image/jpg, .pdf, .HEIC"
                persistent-placeholder
                outlined
                dense
              ></v-file-input>

              <v-text-field
                v-else
                v-model="model.fileName"
                outlined
                label="Nom de fichier"
                type="text"
                persistent-placeholder
                dense
                placeholder="Nom de fichier"
                :rules="[validations.required]"
              ></v-text-field>

              <div class="text-center">
                <v-btn
                  color="info"
                  depressed
                  type="submit"
                  class="form__submit"
                >
                  {{ $t("userActions.confirm") }}
                </v-btn>
              </div>
            </div>
          </v-form>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import validations from "@/validations/index";
export default {
  name: "ChangeDocumentModal",
  props: {
    show: {
      type: Boolean,
    },
    document: {
      type: Object,
      required: true,
    },
    mode: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      dialog: false,
      model: {
        fileName: this.document.filename,
        file: null,
      },
      valid: false,
    };
  },
  watch: {
    show: {
      immediate: true,
      handler(newVal) {
        this.dialog = newVal;
      },
    },
  },
  computed: {
    validations() {
      return validations;
    },
  },
  methods: {
    submit() {
      this.$refs.form.validate();
      if (!this.valid) return;

      let formData = {
        fileName: this.model.fileName,
      };

      if (this.mode === "update") {
        formData.file = this.model.file;
      }

      this.$emit("updateDocument", formData);
      this.$emit("closeModal");
    },
  },
};
</script>

<style src="./ChangeDocumentModal.scss" lang="scss" scoped></style>